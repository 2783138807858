<template>
  <div>
    <div
      class="flex flex-row items-center px-6 pb-4 border-b">
      <span class="text-md">Buscar productos</span>
      <a class="text-purple-400 ml-auto" @click="showCreateProductDialog">
        <strong>+ Agregar nuevo producto</strong>
      </a>
      <div class="ml-4">
        <sa-icon-button
          with-bg
          iconClass="text-xl bx bx-x"
          @click="cancel" />
      </div>
    </div>
    <div class="w-full flex flex-row px-6 py-4">
      <el-input
        v-model="queryProducts.textSearch"
        ref="textSearch">
        <i slot="prefix" class='mt-2 text-xl bx bx-search' />
      </el-input>
      <div class="m-1 ml-4">
        <pagination
          :pagination="pagination"
          @onPaginationChange="onPaginationChange"
        />
      </div>
    </div>
    <div>
      <div
        v-if="filteredProducts.length === 0"
        class="py-8 text-center">
        <div
          v-if="queryProducts.textSearch"
          class="inline-block w-full">
          <i class="text-3xl bx bx-confused" />
          <div>
            <span>No se encontraron productos que coincidan con</span>
            <span class="mx-1 italic font-bold">{{ textSearch }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="px-4 py-2">
          <products-search-list
            :products="paginated"
            @onChangeSelectedProducts="onChangeSelectedProducts"
          />
        </div>
      </div>
    </div>
    <div class="px-6 pt-6 border-t">
      <div
        v-if="errorMessage"
        class="py-2 text-right">
        <span class="text-red-500">{{ errorMessage }}</span>
      </div>
      <div class="flex flex-row justify-end space-x-2">
        <sa-button
          type="secondary"
          @click="cancel">
          Cancelar
        </sa-button>
        <sa-button
          type="primary"
          @click="selectProducts">
          Seleccionar
        </sa-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="isVisibleCreateProductDialog"
      append-to-body
      show-close
      :width="dialogClientWidth">
      <div class="w-full flex justify-between">
        <span class="font-bold text-md">Crear Producto</span>
        <el-button class="el-button--primary ml-auto" @click="createProduct">Guardar</el-button>
      </div>
      <product-form :loading="createProductLoading" :form="createProductForm" />
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import responsiveSize from '@/mixins/responsiveSize';
import ProductService from '@/services/ProductService';

export default {
  name: 'ProductSearch',
  mixins: [error, responsiveSize],
  components: {
    ProductsSearchList: () => import('./ProductsSearchList'),
    Pagination: () => import('@/components/molecules/Pagination'),
    ProductForm: () => import('@/views/app/products/Form'),
  },
  props: {
    textSearch: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      errorMessage: null,
      filteredProducts: [],
      selectedProducts: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalPages: 0,
      },
      dialogClientWidth: '40%',
      createProductLoading: false,
      isVisibleCreateProductDialog: false,
      createProductForm: {
        name: undefined,
        description: undefined,
        category: undefined,
        brand_model: undefined,
        cost: undefined,
        price: undefined,
        quantity: 0,
        minStockAlert: null,
        maxStockAlert: null,
      },
      queryProducts: {
        textSearch: null,
        pageSize: 10,
        pageNumber: 1,
      },
    };
  },
  mounted() {
    this.$refs.textSearch.focus();
    this.queryProducts.textSearch = this.textSearch;
  },
  created() {
    window.addEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  destroyed() {
    window.removeEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  watch: {
    'queryProducts.textSearch': {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadProducts(this.queryProducts);
      }, 300)
    },
  },
  computed: {
    paginated() {
      const products = [...this.filteredProducts];
      const { currentPage, perPage } = this.pagination;
      const pages = [];
      while (products.length) {
        pages.push(products.splice(0, perPage));
      }
      return pages[currentPage - 1];
    },
  },
  methods: {
    async loadProducts(queryProducts) {
      try {
        const result = await ProductService.getAll({
          businessInfoId: this.$store.state.business.id,
          textSearch: queryProducts.textSearch,
        });
        this.filteredProducts = result;
        this.pagination.currentPage = 1;
        this.pagination.totalPages = Math.ceil(this.filteredProducts.length / this.pagination.perPage) || 1;
      }
      catch {
        this.$message.error(this.getErrorMessage(error));
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    selectProducts() {
      if(this.selectedProducts.length === 0) {
        this.errorMessage = 'Seleccione uno o más productos para continuar';
        return;
      }
      this.$emit('onSelectProducts', this.selectedProducts);
    },
    onChangeSelectedProducts(selectedProducts) {
      this.selectedProducts = selectedProducts;
    },
    onPaginationChange(pagination) {
      this.pagination = pagination;
    },
    resizeDialogWidth() {
      const percentageWidth = this.getPercentageWidth();
      this.dialogClientWidth = percentageWidth === '30%' ? '40%' : percentageWidth;
    },
    showCreateProductDialog() {
      this.isVisibleCreateProductDialog = true;
      this.resizeDialogWidth();
    },
    async createProduct() {
      this.createProductLoading = true;
      try {
        const request = {
          name: this.createProductForm.name,
          description: this.createProductForm.description,
          category: this.createProductForm.category,
          brandModel: this.createProductForm.brand_model,
          cost: parseFloat(this.createProductForm.cost),
          price: parseFloat(this.createProductForm.price),
          quantity: parseFloat(this.createProductForm.quantity),
          minStockAlert: parseFloat(this.createProductForm.minStockAlert),
          maxStockAlert: parseFloat(this.createProductForm.maxStockAlert),
        };
        await ProductService.create({ product: request });
        await this.$toastr.s('Se creo con exito');
      } catch(error) {
        this.$toastr.e(this.getErrorMessage(error));
      } finally {
        this.createProductLoading = false;
        this.isVisibleCreateProductDialog = false;
      }
    },
  },
}
</script>
